import React from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../src/assets/images/name-icon.png';
import { faArrowRight,faHeart} from "@fortawesome/free-solid-svg-icons";
import './App.css';
import axios from 'axios'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

function App() {
const [data,setdata]=React.useState([])
const docsPerPage = 30;
const [size,setsize]=React.useState(100)
const [pageNumber, setPageNumber] = React.useState(1);
React.useEffect(()=>{
	RequestAPI(pageNumber,docsPerPage)
	RequestCountApy()
},[])

const handlePageChange = (current, pageSize) => {
	
	setPageNumber(current);
	RequestAPI(current,docsPerPage)
};

const RequestAPI=async(pageNumber,docsPerPage)=>{
	setdata([])
    await axios({
		method:'post',
		url:`${process.env.REACT_APP_SERVER_URL}/v1/apy/AllPairsApy`,
		data:{pageNumber:pageNumber,docsPerPage:docsPerPage}
	}).then((data)=>{
		// console.log(data.data.data)
		setdata(data.data.data)
	})
}

const RequestCountApy=async()=>{
	await axios({
		method:'get',
		url:`${process.env.REACT_APP_SERVER_URL}/v1/apy/countPairsApy`
	}).then((data)=>{
		// console.log(data.data.totalPoolApy)
		setsize(data.data.totalPoolApy)
	})
}
return (
	<div>
	<div className="outer-div">
		<div className="table-responsive">
			<Table className="custom-striped-table" striped bordered hover>
				<thead>
					<tr>
						<th ><div className="d-flex justify-content-center align-items-center pool">Pool Name</div></th>
						{/* <th><span className="d-flex justify-content-center align-items-center pool">Pool Age</span></th> */}
						<th>1h Volume <span className="d-block">(USD)</span></th>
						<th>24h Volume <span className="d-block">(USD)</span></th>
						<th>Liquidity <span className="d-block">(USD)</span></th>
						<th><span className="d-flex justify-content-center align-items-center pool">V/R Ratio</span></th>
						{/* <th>Impermanent <span className="d-block">Loss (24h)</span></th> */}
						<th className="text-center">Fees <span className="d-block">per 1h</span></th>
						<th className="text-center">Fees <span className="d-block">per 24h</span></th>
						{/* <th className="text-center">1y Fees/Reserves<span className="d-block">Since Inception</span></th> */}
						{/* <th><span className="d-flex justify-content-center align-items-center pool">Last 7/14/30 Day Avg</span></th> */}
						<th><span className="d-flex justify-content-center align-items-center pool">View</span></th>
					</tr>
				</thead>
				<tbody>
					{data.map((apy,i)=>{
						return <tr key={i}>
						<td className="middle">
							<div className="d-flex"><span className=" me-3"><img src={Icon} /></span>
								<div><strong className="name d-block">{apy.poolName}</strong><span className="d-block">{apy.dexName}</span></div>
							</div>
						</td>
						<td><span className="mid">${apy.poolHourVolume ? (apy.poolHourVolume/1000).toFixed(8)+"k" : 0}</span></td>
						<td><span className="mid">${apy.poolDayVolume ? (apy.poolDayVolume/1000).toFixed(8)+"k" : 0}</span></td>
						<td><span className="mid">${apy.poolTotalVolume ? (apy.poolTotalVolume/1000).toFixed(8)+"k" : 0 }</span></td>
						{/* <td><span className="mid">${apy.volumeReserveRatioPerHour ? apy.volumeReserveRatioPerHour : 0 }</span></td> */}
						<td><span className="mid">${apy.volumeReserveRatioPerDay ? (apy.volumeReserveRatioPerDay).toFixed(8) : 0 }</span></td>
						{/* <td className="red"><strong className="mid">-$95.18</strong></td> */}
						<td className="green"><strong className="mid">${apy.feePerHour ? (apy.feePerHour).toFixed(8) : 0}</strong></td>
						<td className="green"><strong className="mid">${apy.feePerDay ? (apy.feePerDay).toFixed(8) : 0}</strong></td>
						{/* <td className="text-center"><span className="d-block mb-2">17.75% </span><span className="tag d-inline-block">+51.66% bonus</span></td>
						<td>
							<span className="d-block">5.10% <FontAwesomeIcon className="red right-icon" icon={faArrowRight} /></span>
							<span className="d-block">6.24% <FontAwesomeIcon className="red right-icon" icon={faArrowRight} /></span>
							<span className="d-block">10.62%<FontAwesomeIcon className="red right-icon" icon={faArrowRight} /></span>
						</td> */}
						<td ><div className="d-flex text-center mid"><a href="" className="icon-circle mx-3 text-decoration-none"> <FontAwesomeIcon icon={faHeart} /> </a><a href="" className="icon-circle text-decoration-none"><FontAwesomeIcon icon={faArrowRight} /> </a></div></td>
					</tr>;})}
				</tbody>
			</Table>
		</div>
	</div>
	<div className="row justify-content-md-center"  style={{ float: 'right',marginRight:'100px',marginBottom:'100px', }}> 
		                          <Pagination
                                    defaultPageSize={docsPerPage}
                                    pageSize={docsPerPage}
									defaultCurrent={pageNumber}
                                    current={pageNumber}
                                    total={size}
                                    onChange={handlePageChange}
                                    
                                />
								</div>

</div>
);
}

export default App;
